import axios from 'axios';
import { configStore } from '../store';
import AuthenticationService from './AuthenticationService';

export default {
  matriculeToUsername(matricule) {
    return matricule + '@admin.tap2use-sytral.fr';
  },

  async createController(lastname, firstname, operator, matricule) {
    let email = this.matriculeToUsername(matricule);
    let payload = {
      pinCredentials: {
        identificationNumber: matricule,
      },
      role: 'CONTROL',
      userCredentials: {
        username: email,
      },
      userDetails: {
        companyReference: operator,
        userEmailAddress: email,
        userFirstName: firstname,
        userLastName: lastname,
      },
    };

    let accessToken = await AuthenticationService.getInstance().getAccessToken();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: ' bearer ' + accessToken,
      'X-TENANT': configStore.config.tenant,
    };

    return axios.post(
      configStore.config.apiUrl +
        '/mobility-engine/v1/realms/' +
        configStore.config.kcRealm +
        '/clients/' +
        configStore.config.kcClientId +
        '/users',
      payload,
      { headers: headers }
    );
  },

  getUserInfo(username, headers) {
    return axios
      .get(
        configStore.config.apiUrl +
          '/mobility-engine/v1/realms/' +
          configStore.config.kcRealm +
          '/username/' +
          username +
          '/user',
        { headers: headers }
      )
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  },

  async resetPin(matricule) {
    let accessToken = await AuthenticationService.getInstance().getAccessToken();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: ' bearer ' + accessToken,
      'X-TENANT': configStore.config.tenant,
    };

    let userinfo = await this.getUserInfo(this.matriculeToUsername(matricule), headers);
    let userId = userinfo.data.userCredentials.userId;

    let payload = {
      identificationNumber: userinfo.data.pinCredentials.identificationNumber,
      pin: userinfo.data.pinCredentials.pin,
    };

    return axios.put(configStore.config.apiUrl + '/mobility-engine/v1/users/' + userId + '/_update-pin', payload, {
      headers: headers,
    });
  },
};
