import axios from 'axios';
import AuthenticationService from './AuthenticationService';
import { configStore } from '../store';

export default class TransportOfferService {
  constructor() {}

  async offers(external, origin) {
    let headers = '';
    let accessToken = await AuthenticationService.getInstance().getAccessToken();
    headers = {
      'Content-Type': 'application/json',
      Authorization: ' bearer ' + accessToken,
      'X-TENANT': configStore.config.tenant,
    };
    return axios.get(
      configStore.config.apiUrl + '/financial-engine/api/risk-manager-' + origin + '/v1/transportoffers',
      {
        headers: headers,
        params: {
          reference: external,
        },
      }
    );
  }
}
