<template>
  <div class="card">
    <div class="card-header">Terminaux</div>
    <div class="overflow-auto">
      <b-table
        id="devices-table"
        :items="devicesList"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :outlined="true"
        :hover="true"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :small="true"
        responsive
      >
        <template #cell(poi)="data">
          <span>{{ data.item.poi }}</span>
          <a class="ml-1" target="_blank" :href="urlRedirectPOI(data.item.poi)">
            <b-icon icon="box-arrow-up-right"></b-icon
          ></a>
        </template>
        <template #cell(status4g)="data">
          <b-badge :class="['status-badge', { warning: data.item.status4g.isWarning }]">{{
            data.item.status4g.statut
          }}</b-badge>
        </template>

        <template #head(activityIndicator)>
          <b-icon icon="heart-fill" id="popover-activity-indicator" class="ml-2"></b-icon>
          <b-popover target="popover-activity-indicator" triggers="hover" placement="bottom">
            Indicateur d'activité <br />
            (vert) Actif <br />
            (orange) Dernier Tap > 24h <br />
            (rouge) Dernière Connexion > 24h
          </b-popover>
        </template>

        <template #cell(activityIndicator)="data">
          <b-icon v-if="data.item.activityIndicator == 'OK'" icon="check" variant="success" font-scale="2"></b-icon>
          <b-icon
            v-if="data.item.activityIndicator == 'NA'"
            icon="exclamation"
            style="color: #8a6d3b"
            font-scale="2"
          ></b-icon>
          <b-icon v-if="data.item.activityIndicator == 'KO'" icon="x" variant="danger" font-scale="2"></b-icon>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="devices-table"
        align="right"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { configStore } from '@/store';

export default {
  props: {
    devicesList: Array,
  },
  data() {
    return {
      sortBy: 'dernierTap',
      sortDesc: false,
      perPage: 4,
      currentPage: 1,
      fields: [
        { key: 'poi', label: 'POI' },
        { key: 'patrimony', label: 'Patrimoine' },
        { key: 'version', label: 'Version' },
        { key: 'status4g', label: '4G' },
        { key: 'location', label: 'Empl.' },
        { key: 'lastTap', label: 'Dernier Tap', sortable: true },
        { key: 'lastDeviceInfo', label: 'Dernière connexion', sortable: true },
        { key: 'activityIndicator' },
      ],
    };
  },
  methods: {
    urlRedirectPOI(poi) {
      return configStore.config.adminPortalUrl + 'devices/' + poi + '/informations';
    },
  },
  computed: {
    rows() {
      return this.devicesList.length;
    },
  },
};
</script>
<style lang="scss" scoped>
.status-badge {
  background-color: #5cb85c;
  &.warning {
    background-color: #d9534f;
  }
}
</style>
