/**
 * Config Store
 * Holds global app configuration properties
 */
export const configStore = {
  config: {
    oidcAuthorityUrl: 'http://localhost:8081/auth/realms/spa-oidc-demo',
    oidcClientId: 'admin-custom',
    externalServiceUrl: 'http://localhost:8082',
  },
};
