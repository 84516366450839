import Oidc from 'oidc-client';
import { configStore } from '../store';

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.INFO;

class AuthenticationLayer {
  constructor() {
    this.userManager = new Oidc.UserManager({
      userStore: new Oidc.WebStorageStateStore(),
      authority: configStore.config.oidcAuthorityUrl,
      client_id: configStore.config.oidcClientId,
      redirect_uri: window.location.origin + '/#/callback',
      response_type: 'id_token token',
      scope: 'openid profile address roles offline_access',
      post_logout_redirect_uri: window.location.origin + '/index.html',
      silent_redirect_uri: window.location.origin + '/#/renew',
      automaticSilentRenew: true,
      // 20 seconds timeout, to allow app reload on slow networks
      silentRequestTimeout: 20000,
      filterProtocolClaims: true,
      loadUserInfo: true,
    });

    let self = this;

    // Wait for logout event (login required) to clear the session state
    this.userManager.events.addUserSignedOut(function () {
      console.log('User signed out, proceed to logout and session clearing');
      self.signOut();
    });

    // see https://github.com/IdentityModel/oidc-client-js/issues/787
    this.userManager.events.addAccessTokenExpired(() => this.userManager.signinSilent());
  }

  // Return true if the user is signed
  isSignedIn() {
    return new Promise((resolve, reject) => {
      this.userManager
        .getUser()
        .then(function (user) {
          if (user == null) {
            return resolve(false);
          } else {
            return resolve(true);
          }
        })
        .catch(function (err) {
          console.log(err);
          return reject(err);
        });
    });
  }

  // Renew the token manually
  renewToken() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.userManager
        .signinSilent()
        .then(function (user) {
          if (user == null) {
            self.signIn(null);
          } else {
            return resolve(user);
          }
        })
        .catch(function (err) {
          console.log(err);
          return reject(err);
        });
    });
  }

  signinSilentCallback() {
    return this.userManager.signinSilentCallback();
  }

  // Get the user who is logged in
  getUser() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.userManager
        .getUser()
        .then(function (user) {
          if (user == null) {
            self.signIn();
            return resolve(null);
          } else {
            return resolve(user);
          }
        })
        .catch(function (err) {
          console.log(err);
          return reject(err);
        });
    });
  }

  // Check if there is any user logged in
  getSignedIn() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.userManager
        .getUser()
        .then(function (user) {
          if (user == null) {
            self.signIn();
            return resolve(false);
          } else {
            return resolve(true);
          }
        })
        .catch(function (err) {
          console.log(err);
          return reject(err);
        });
    });
  }

  // Redirect of the current window to the authorization endpoint.
  signIn() {
    this.userManager.signinRedirect().catch(function (err) {
      console.log(err);
    });
  }

  signinRedirectCallback() {
    return this.userManager.signinRedirectCallback();
  }

  // Redirect of the current window to the end session endpoint
  signOut() {
    this.userManager
      .signoutRedirect()
      .then(function () {})
      .catch(function (err) {
        console.log(err);
      });
  }

  // Get the profile of the user logged in
  getProfile() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.userManager
        .getUser()
        .then(function (user) {
          if (user == null) {
            self.signIn();
            return resolve(null);
          } else {
            return resolve(user.profile);
          }
        })
        .catch(function (err) {
          console.log(err);
          return reject(err);
        });
    });
  }

  // Get the token id
  getIdToken() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.userManager
        .getUser()
        .then(function (user) {
          if (user == null) {
            self.signIn();
            return resolve(null);
          } else {
            return resolve(user.id_token);
          }
        })
        .catch(function (err) {
          console.log(err);
          return reject(err);
        });
    });
  }

  // Get the session state
  getSessionState() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.userManager
        .getUser()
        .then(function (user) {
          if (user == null) {
            self.signIn();
            return resolve(null);
          } else {
            return resolve(user.session_state);
          }
        })
        .catch(function (err) {
          console.log(err);
          return reject(err);
        });
    });
  }

  // Get the access token of the logged in user
  getAccessToken() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.userManager
        .getUser()
        .then(function (user) {
          if (user == null) {
            self.signIn();
            return resolve(null);
          } else {
            return resolve(user.access_token);
          }
        })
        .catch(function (err) {
          console.log(err);
          return reject(err);
        });
    });
  }

  // Takes the scopes of the logged in user
  getScopes() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.userManager
        .getUser()
        .then(function (user) {
          if (user == null) {
            self.signIn();
            return resolve(null);
          } else {
            return resolve(user.scopes);
          }
        })
        .catch(function (err) {
          console.log(err);
          return reject(err);
        });
    });
  }

  // Get the user roles logged in
  getRole() {
    let self = this;
    return new Promise((resolve, reject) => {
      this.userManager
        .getUser()
        .then(function (user) {
          if (user == null) {
            self.signIn();
            return resolve(null);
          } else {
            return resolve(user.profile.role);
          }
        })
        .catch(function (err) {
          console.log(err);
          return reject(err);
        });
    });
  }
}

export default class AuthenticationService {
  constructor() {
    throw new Error('Use AuthenticationService.getInstance()');
  }

  static getInstance() {
    if (!AuthenticationService.instance) {
      AuthenticationService.instance = new AuthenticationLayer();
    }
    return AuthenticationService.instance;
  }
}
