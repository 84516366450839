import { configStore } from '../store';
import rison from 'rison';

export default class KibanaService {
  kibanaUrl = configStore.config.kibanaUrl;
  kibanaStatisticDashboardId = configStore.config.kibanaDashboardId;

  constructor() {}

  createUrl() {
    // Load the kibana source
    const url = new URL(this.kibanaStatisticDashboardId, this.kibanaUrl.replace('#', 'ANCHOR_TAG'));

    // Add the embed mode
    url.searchParams.append('embed', 'true');
    // Build the date time query
    const dateTimeQuery = {
      filters: [],
      refreshInterval: {
        pause: true,
        value: 0,
      },
      time: {
        from: 'now-24h',
        to: 'now',
      },
    };

    // Apply the datetime query to the url
    url.searchParams.append('_g', rison.encode(dateTimeQuery));
    url.searchParams.append('show-time-filter', true);
    url.searchParams.append('hide-filter-bar', true);
    url.href = url.href.replace('ANCHOR_TAG', '#');
    return url.href;
  }
}
