import axios from 'axios';
import { configStore } from '../store';
import AuthenticationService from './AuthenticationService';

export default {
  async getVehiculeInfo(vehiculeNumber) {
    let accessToken = await AuthenticationService.getInstance().getAccessToken();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: ' bearer ' + accessToken,
      'X-TENANT': configStore.config.tenant,
    };

    let payload = {
      placeReference: 'CONTAINS:' + vehiculeNumber,
    };

    let reponse = await axios.get(
      configStore.config.apiUrl + '/device-engine/api/device-configuration-manager/v3/devices',
      {
        headers: headers,
        params: payload,
      }
    );

    return reponse.data.content.filter((device) => device.state.location.placeReference === vehiculeNumber);
  },

  async getStationNameFromId(stationId) {
    let accessToken = await AuthenticationService.getInstance().getAccessToken();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: ' bearer ' + accessToken,
      'X-TENANT': configStore.config.tenant,
    };

    let payload = {
      stopReference: stationId,
    };

    return axios.get(configStore.config.apiUrl + '/fc-engine-api/api/location-catalog/api/v1/search/stops', {
      headers: headers,
      params: payload,
    });
  },

  async getSimNumberFromDeviceReference(deviceReference) {
    let accessToken = await AuthenticationService.getInstance().getAccessToken();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: ' bearer ' + accessToken,
    };
    let response = await axios.get('/sim-number/' + deviceReference, {
      headers: headers,
    });
    return response.data;
  },

  async getFixeInfo(fixeNumber) {
    let accessToken = await AuthenticationService.getInstance().getAccessToken();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: ' bearer ' + accessToken,
      'X-TENANT': configStore.config.tenant,
    };

    let payload = {
      genericParameter: 'CUSTOM_PLACE|' + fixeNumber,
    };

    let reponse = await axios.get(
      configStore.config.apiUrl + '/device-engine/api/device-configuration-manager/v3/devices',
      {
        headers: headers,
        params: payload,
      }
    );

    return reponse.data.content;
  },

  async getPatrimoineInfo(patrimony) {
    let accessToken = await AuthenticationService.getInstance().getAccessToken();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: ' bearer ' + accessToken,
      'X-TENANT': configStore.config.tenant,
    };

    let payload = {
      deviceReference: 'CONTAINS:' + patrimony,
    };

    let reponse = await axios.get(
      configStore.config.apiUrl + '/device-engine/api/device-configuration-manager/v3/devices',
      {
        headers: headers,
        params: payload,
      }
    );

    return reponse.data.content.filter((device) => device.state.deviceReference === patrimony);
  },
};
