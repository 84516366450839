export default {
  formatFrenchDate(date) {
    if (typeof date === 'undefined') {
      return null;
    }
    const event = new Date(date);
    return event.toLocaleDateString('fr') + ' ' + event.toLocaleTimeString('fr');
  },

  formatDateWithTZ(date, locale, timeZone) {
    if (date) {
      return new Date(date).toLocaleString(locale, {
        timeZone: timeZone,
      });
    }
    return null;
  },
};
