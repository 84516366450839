<template>
  <div v-if="user">
    <b-container>
      <b-row class="mt-3 mb-5">
        <div class="col-md-12">
          <h2>
            Bienvenue,
            <small class="text-muted">{{ user['given_name'] }}</small>
          </h2>
          <div>
            <span> Vous êtes connecté avec un profil : </span>
            <b-badge v-for="role in user.roles" v-bind:key="role" class="mr-1" variant="success">{{ role }}</b-badge>
          </div>
        </div>
      </b-row>
      <b-row class="mt-4">
        <div class="col-md-12">
          <h3>Terminaux</h3>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <router-link class="stretched-link" to="/research/vehicule">Accéder aux véhicules</router-link>
              <b-icon icon="chevron-right" class="float-right"></b-icon>
            </b-card>
          </b-card-group>
        </div>
      </b-row>
      <b-row v-if="hasRole('ROOT_ADMIN')" class="mt-4">
        <div class="col-md-12">
          <h3>Contrôle et PDVVC</h3>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <router-link class="stretched-link" to="/controller/add">Ajouter un contrôleur</router-link>
              <b-icon icon="chevron-right" class="float-right mt-1"></b-icon>
            </b-card>
            <b-card header-tag="header" footer-tag="footer">
              <router-link class="stretched-link" to="/controller/resetpin"
                >Réinitialiser le PIN d'un contrôleur</router-link
              >
              <b-icon icon="chevron-right" class="float-right mt-1"></b-icon>
            </b-card>
          </b-card-group>
        </div>
      </b-row>
      <b-row v-if="hasRole('ROOT_ADMIN')" class="mt-4">
        <div class="col-md-12">
          <h3>Comptabilité</h3>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <router-link class="stretched-link" to="/TransportOffer">Consulter une offre de Transport</router-link>
              <b-icon icon="chevron-right" class="float-right mt-1"></b-icon>
            </b-card>
          </b-card-group>
        </div>
      </b-row>
      <b-row v-if="hasRole('ROOT_ADMIN')" class="mt-4">
        <div class="col-md-12">
          <h3>Autres</h3>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <router-link class="stretched-link" to="/statistcs">Statistiques avancées</router-link>
              <b-icon icon="chevron-right" class="float-right mt-1"></b-icon>
            </b-card>
          </b-card-group>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import AuthenticationService from '../services/AuthenticationService';
import Authenticated from '../mixins/Authenticated';

export default {
  name: 'Home',
  mixins: [Authenticated],

  data() {
    return {
      user: null,
    };
  },
  mounted() {
    AuthenticationService.getInstance()
      .getProfile()
      .then((profile) => {
        this.user = profile;
      });
  },
};
</script>

<style scoped>
.card {
  max-width: 300px;
}
</style>
