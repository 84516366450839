<template>
  <div>
    <iframe :src="kibanaDashboardUrl" title="Statistics" seamless></iframe>
  </div>
</template>

<script>
import KibanaService from '../services/kibanaService';

export default {
  name: 'Statistics',

  data() {
    return {
      kibanaService: new KibanaService(),
      kibanaDashboardUrl: '',
    };
  },
  mounted() {
    this.kibanaDashboardUrl = this.kibanaService.createUrl();
  },
};
</script>

<style scoped>
iframe {
  width: 100%;
  height: 1000px;
}
</style>
