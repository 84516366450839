import axios from 'axios';
import { configStore } from '../store';
import AuthenticationService from './AuthenticationService';

export default {
  async auth() {
    let accessToken = await AuthenticationService.getInstance().getAccessToken();

    return {
      'Content-Type': 'application/json',
      Authorization: 'bearer ' + accessToken,
    };
  },

  async simActivation(simSerialNumber) {
    return axios.post(
      configStore.config.apiUrl + '/sim/' + simSerialNumber + '/reactivate',
      {},
      {
        headers: await this.auth(),
      }
    );
  },

  async simSuspension(simSerialNumber) {
    return axios.post(
      configStore.config.apiUrl + '/sim/' + simSerialNumber + '/suspend',
      {},
      {
        headers: await this.auth(),
      }
    );
  },

  async simStatus(simSerialNumber) {
    return axios.get(configStore.config.apiUrl + '/sim/' + simSerialNumber + '/status', {
      headers: await this.auth(),
    });
  },
};
