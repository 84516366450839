<template>
  <div class="card">
    <div class="card-header">History</div>
    <div class="card-body">
      <div class="vtl">
        <div v-for="(item, index) in history" v-bind:key="index" class="event">
          <b-badge class="float-right" variant="secondary">{{ item.trigger }}</b-badge>
          <p v-if="item.completeAt">
            {{ formatFrenchDate(item.completeAt) }}
          </p>
          <div v-if="item.acceptanceOperations[0]">{{ item.acceptanceOperations[0].type }}</div>
          <div>
            Status:
            <span :style="[item.status == 'FAILED' ? { color: 'red' } : { color: 'green' }]">
              {{ item.status }}
            </span>
          </div>
          <div v-if="item.type">Type : {{ item.type }}</div>
          <b-button v-b-toggle="`collapse-operations-${index}`" variant="info" size="sm" class="mt-2"
            >View operation details</b-button
          >
          <b-collapse :id="`collapse-operations-${index}`" class="mt-2">
            <b-card>
              <pre>{{ JSON.stringify(item.acceptanceOperations, null, 2) }}</pre>
            </b-card>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from '@/utils/Utils';

export default {
  data: () => ({}),
  props: {
    history: Array,
  },

  methods: {
    formatFrenchDate(date) {
      return Utils.formatFrenchDate(date);
    },
  },
};
</script>

<style scoped>
.vtl {
  position: relative;
  padding: 10px 10px 10px 50px;
  max-width: 500px;
}
.vtl,
.vtl * {
  box-sizing: border-box;
}

.vtl::before {
  content: '';
  width: 5px;
  background-color: #17a2b8;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
}

div.event {
  padding: 20px 30px;
  background-color: #f2f2f2;
  position: relative;
  border-radius: 6px;
  margin-bottom: 10px;
}

p.txt {
  margin: 10px 0 0 0;
  color: #222;
}

div.event::before {
  content: '';
  border: 10px solid transparent;
  border-right-color: #f2f2f2;
  border-left: 0;
  position: absolute;
  top: 20%;
  left: -10px;
}

div.event::after {
  content: '';
  background: #fff;
  border: 4px solid #17a2b8;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  top: 20%;
  left: -40px;
}
</style>
