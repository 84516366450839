<template>
  <div class="card">
    <div class="card-header">General Information</div>
    <b-list-group v-if="generalInformation.id">
      <b-list-group-item>
        <b-card-sub-title>Id </b-card-sub-title> {{ generalInformation.id }}
        <CopyToClipboardButton :data="generalInformation.id"></CopyToClipboardButton>
      </b-list-group-item>
      <b-list-group-item>
        <b-card-sub-title>Reference </b-card-sub-title> {{ generalInformation.reference
        }}<CopyToClipboardButton :data="generalInformation.reference"></CopyToClipboardButton>
      </b-list-group-item>
      <b-list-group-item>
        <b-card-sub-title>Service Operator </b-card-sub-title> {{ generalInformation.serviceOperator
        }}<CopyToClipboardButton :data="generalInformation.serviceOperator"></CopyToClipboardButton>
      </b-list-group-item>
      <b-list-group-item>
        <b-card-sub-title>Status </b-card-sub-title> {{ generalInformation.status
        }}<CopyToClipboardButton :data="generalInformation.status"></CopyToClipboardButton>
      </b-list-group-item>
      <b-list-group-item>
        <b-card-sub-title>Access Media Reference </b-card-sub-title> {{ generalInformation.accessMediaReference
        }}<CopyToClipboardButton :data="generalInformation.accessMediaReference"></CopyToClipboardButton>
      </b-list-group-item>
      <b-list-group-item>
        <b-card-sub-title>Opened At </b-card-sub-title> {{ formatFrenchDate(generalInformation.openedAt)
        }}<CopyToClipboardButton :data="formatFrenchDate(generalInformation.openedAt)"></CopyToClipboardButton>
      </b-list-group-item>
      <b-list-group-item>
        <b-card-sub-title>Closed At </b-card-sub-title> {{ formatFrenchDate(generalInformation.closedAt)
        }}<CopyToClipboardButton :data="formatFrenchDate(generalInformation.closedAt)"></CopyToClipboardButton>
      </b-list-group-item>
      <b-list-group-item>
        <b-card-sub-title>Aid </b-card-sub-title> {{ generalInformation.aid
        }}<CopyToClipboardButton :data="generalInformation.aid"></CopyToClipboardButton>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import CopyToClipboardButton from '../common/CopyToClipboardButton.vue';
import Utils from '@/utils/Utils';

export default {
  components: {
    CopyToClipboardButton,
  },
  data() {
    return {};
  },

  props: {
    generalInformation: [],
  },
  methods: {
    formatFrenchDate(date) {
      return Utils.formatFrenchDate(date);
    },
  },
};
</script>
