const Authenticated = {
  methods: {
    hasRole: function (...roles) {
      if (this.user && this.user.roles) {
        let userRoles = this.user.roles;
        for (let role of roles) {
          if (userRoles.includes(role)) {
            return true;
          }
        }
      }

      return false;
    },
  },
};

export default Authenticated;
