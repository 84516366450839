<template>
  <div>
    <b-row align-v="center">
      <b-col>
        <b-form @submit="onSubmit">
          <b-form-group id="input-group-vehiculenumber" label-for="input-vehiculenumber">
            <b-input-group class="mb-2">
              <b-input-group-prepend is-text> Numéro de {{ researchType }} </b-input-group-prepend>
              <b-form-input id="input-vehiculenumber" v-model="researchNumber" required></b-form-input>
              <b-button variant="primary" type="submit"><b-icon icon="search"></b-icon></b-button>
            </b-input-group>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: {
    researchType: String,
  },
  data() {
    return {
      researchNumber: '',
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.$emit('emitResearch', this.researchNumber);
    },
  },
};
</script>
