<template>
  <div>Pending redirect...</div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService';

export default {
  name: 'AuthSigninCallback',
  data() {
    return {};
  },
  mounted() {
    let router = this.$router;
    AuthenticationService.getInstance()
      .signinRedirectCallback()
      .then(function () {
        router.push('/');
      })
      .catch(function (err) {
        console.log(err);
      });
  },
};
</script>
