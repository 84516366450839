<template>
  <div class="card">
    <div class="card-header">Validation taps</div>
    <div class="card-body">
      <b-table responsive bordered :items="events" :fields="fields" striped>
        <template #cell(details)="row">
          <b-button size="sm" variant="info" @click="row.toggleDetails">
            <b-icon v-if="row.detailsShowing" icon="eye-slash-fill"></b-icon>
            <b-icon v-else icon="eye-fill"></b-icon>
          </b-button>
        </template>

        <template #cell(id)="row">
          <p>...{{ row.item.transactionId.substr(-4) }}</p>
        </template>

        <template #cell(issueDate)="row">
          <p>{{ formatFrenchDate(row.item.issueDate) }}</p>
        </template>

        <template #cell(transportMode)="row">
          <p>{{ row.item.locationInfo.transportMode }}</p>
        </template>
        <template #cell(passengers)="row">
          <p>{{ row.item.passengerQty }}</p>
        </template>
        <template #cell(serviceOperator)="row">
          <p>{{ row.item.serviceOperator }}</p>
        </template>

        <template #row-details="row">
          <b-list-group>
            <b-list-group-item>
              <b-card-sub-title>Transaction Id </b-card-sub-title>{{ row.item.transactionId
              }}<CopyToClipboardButton :data="row.item.transactionId"></CopyToClipboardButton>
            </b-list-group-item>
            <b-list-group-item>
              <b-card-sub-title>issueDate </b-card-sub-title> {{ formatFrenchDate(row.item.issueDate)
              }}<CopyToClipboardButton :data="formatFrenchDate(row.item.issueDate)"></CopyToClipboardButton>
            </b-list-group-item>
            <b-list-group-item>
              <b-card-sub-title>integrationDate </b-card-sub-title> {{ formatFrenchDate(row.item.integrationDate) }}
              <CopyToClipboardButton :data="formatFrenchDate(row.item.integrationDate)"></CopyToClipboardButton>
            </b-list-group-item>
            <b-list-group-item>
              <b-card-sub-title>Amount </b-card-sub-title> {{ row.item.amount
              }}<CopyToClipboardButton :data="row.item.amount"></CopyToClipboardButton>
            </b-list-group-item>
            <b-list-group-item>
              <b-card-sub-title>Service Operator </b-card-sub-title> {{ row.item.serviceOperator
              }}<CopyToClipboardButton :data="row.item.serviceOperator"></CopyToClipboardButton>
            </b-list-group-item>
            <b-list-group-item>
              <b-card-sub-title>Status </b-card-sub-title> {{ row.item.passengerQty
              }}<CopyToClipboardButton :data="row.item.passengerQty"></CopyToClipboardButton>
            </b-list-group-item>
            <b-list-group-item>
              <b-card-sub-title>Transport Mode </b-card-sub-title>
              <p v-if="row.item.locationInfo">
                {{ row.item.locationInfo.transportMode
                }}<CopyToClipboardButton :data="row.item.locationInfo.transportMode"></CopyToClipboardButton>
              </p>
            </b-list-group-item>
          </b-list-group>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import CopyToClipboardButton from '../common/CopyToClipboardButton.vue';
import Utils from '@/utils/Utils';

export default {
  components: {
    CopyToClipboardButton,
  },

  data() {
    return {
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'issueDate',
          label: 'Issue Date',
        },
        {
          key: 'amount',
          label: 'Amount',
        },
        {
          key: 'serviceOperator',
          label: 'Operator',
        },
        {
          key: 'passengers',
          label: 'Passengers',
        },
        {
          key: 'transportMode',
          label: 'Mode',
        },
        {
          key: 'details',
          label: 'Details',
        },
      ],
    };
  },
  props: {
    events: [],
  },
  methods: {
    formatFrenchDate(date) {
      return Utils.formatFrenchDate(date);
    },
  },
};
</script>
