<template>
  <b-button size="sm" variant="light" v-if="data" v-on:click="copy()">
    <span class="fas fa-paste"></span>
  </b-button>
</template>
<script>
export default {
  data() {
    return {};
  },

  props: {
    data: [],
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.data).then(() => {});
    },
  },
};
</script>
