<template>
  <form role="form">
    <b-input-group>
      <template #prepend>
        <b-input-group-text>Reference</b-input-group-text>
      </template>
      <b-form-input
        id="input-transport-offer"
        v-model="transportOfferReference"
        placeholder="TransportOffer"
        required
      ></b-form-input>
      <b-input-group-append>
        <b-input-group-text>{{ origin }}</b-input-group-text>
        <b-button variant="primary" :to="'/TransportOffer/' + transportOfferReference"
          ><b-icon icon="search"></b-icon
        ></b-button>
      </b-input-group-append>
    </b-input-group>
  </form>
</template>

<script>
export default {
  data() {
    return {
      transportOfferReference: '',
    };
  },
  computed: {
    origin() {
      // display the origin of the file number
      switch (this.transportOfferReference[0]) {
        case 'M':
          return 'Mastercard';
        case 'V':
          return 'Visa';
        case 'C':
          return 'CB';
        default:
          return 'origin';
      }
    },
  },
};
</script>

<style scoped>
.origne {
  margin: 10px 10px 5px 5px;
  padding: 10px;
  background-color: peru;
  border-radius: 10px;
  min-width: 150px;
}
</style>
