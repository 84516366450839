import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';

import App from './App.vue';
import router from './router';
import { configStore } from './store';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import '@fortawesome/fontawesome-free/js/all';

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false;

/**
 * Loads environment configuration before Vue Js mount.
 * Configuration is loaded from a public config directory on remote server
 * and saved to the app store.
 */
axios
  .get('/config/env.json')
  .then((response) => {
    configStore.config = response.data;
  })
  .finally(() => {
    new Vue({
      router,
      render: (h) => h(App),
    }).$mount('#app');
  });
