<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <TerminalSearch @emitResearch="onNewResearch" :researchType="this.type" />
          <span class="h5 ml-5" v-if="show == false">
            <b-icon icon="exclamation-circle-fill" variant="secondary"></b-icon>
            Aucun terminal trouvé pour le {{ this.type }} n°{{ this.num }}
          </span>
        </b-col>
      </b-row>
      <b-row v-if="show">
        <b-col>
          <div>
            <span class="h3">
              <b-icon icon="x-diamond"></b-icon>
              {{ this.type.charAt(0).toUpperCase() + this.type.slice(1) }} n°{{ this.num }}
            </span>
          </div>
          <hr />
        </b-col>
      </b-row>
      <b-row align-h="around" class="info">
        <b-col lg="3" v-if="show" class="mb-3"><TerminalInfo :GenericInfo="genericInfo" /></b-col>
        <b-col lg="9" v-if="show">
          <b-row>
            <b-col lg="12">
              <DevicesList :devicesList="devicesList" />
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col lg="12">
              <SimInfo :simList="simList" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TerminalSearch from '@/components/terminalResearch/TerminalSearchForm.vue';
import TerminalInfo from '@/components/terminalResearch/TerminalInfo.vue';
import DevicesList from '@/components/terminalResearch/DevicesList.vue';
import SimInfo from '@/components/terminalResearch/SimInfo.vue';
import DevicesListService from '@/services/DevicesListService.js';
import locationUtils from '@/utils/locationUtils.js';
import utils from '@/utils/Utils.js';

export default {
  components: { TerminalSearch, TerminalInfo, DevicesList, SimInfo },
  data() {
    return {
      show: null,
      num: null,
      genericInfo: null,
      devicesList: null,
      simList: null,
      warning4g: false,
      type: null,
    };
  },
  methods: {
    async onNewResearch(id) {
      this.devicesList = new Array();
      this.simList = new Array();

      try {
        let devices;

        switch (this.type) {
          case 'portillon':
            devices = await DevicesListService.getFixeInfo(id);
            break;
          case 'vehicule':
            devices = await DevicesListService.getVehiculeInfo(id);
            break;
          case 'patrimoine':
            devices = await DevicesListService.getPatrimoineInfo(id);
            break;
          default:
            break;
        }

        this.num = id;

        if (devices.length == 0) {
          this.show = false;
          return;
        }

        // Data research for the generic info block
        this.getGenericInfo(devices);

        // Data research for detailed info blocks
        this.getDetailedInfos(devices);

        // Display all the info blocks
        this.show = true;
      } catch (error) {
        this.show = false;
        console.log(error);
        this.$bvToast.toast('Une erreur est survenue lors de la recherche', {
          title: 'Erreur',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger',
          solid: true,
        });
      }
    },

    getGenericInfo(devices) {
      // Company reference
      let company = locationUtils.companyNameFromCompanyReference(devices[0].configuration.validator.companyReference);

      // Transport mode and location
      let transportMode = devices[0].configuration.parameters.transportMode;
      let transport = locationUtils.getTransportModeFullName(transportMode);
      let stationId = null;
      let location = '';
      if (transportMode == 'BUS' || transportMode == 'TRAM') {
        location = locationUtils.getOnBoardTransportUnit(devices[0].genericParameters);
      } else {
        stationId = devices[0].configuration.validator.stationReference;
        if (stationId) {
          locationUtils
            .stationNameFromStationId(stationId)
            .then((response) => {
              location = response;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }

      let installationType = locationUtils.getInstallationType(devices[0].configuration.parameters.installationType);

      this.genericInfo = [
        { field: 'Numéro', value: this.num },
        { field: 'Type', value: installationType },
        { field: 'Mode de transport', value: transport },
        { field: 'Exploitant', value: company },
        { field: 'Emplacement', value: location },
      ];
    },

    async getDetailedInfos(devices) {
      for (const device of devices) {
        let frenchTimeZoneLastTap = utils.formatDateWithTZ(
          device.state.metrics.lastTransmittedTap,
          'fr-FR',
          'Europe/Paris'
        );
        let lastDeviceInfoDisplay = utils.formatDateWithTZ(device.state.date, 'fr-FR', 'Europe/Paris');

        let statut4g = device.state.metrics['4g'];
        let stat4g = locationUtils.FourGStatusFromReference(statut4g);
        this.warning4g = false;
        if (stat4g == 'KO') {
          this.warning4g = true;
        }

        let locationNumber = '';
        if (typeof device.genericParameters != 'undefined') {
          locationNumber = locationUtils.getTerminalLocation(device.genericParameters);
        }

        let healthIndicators = device.healthIndicators.filter((i) => ['Activity'].includes(i.name));

        this.devicesList.push({
          poi: device.poi,
          patrimony: device.state.deviceReference,
          version: device.state.firmwareVersion,
          location: locationNumber,
          lastTap: frenchTimeZoneLastTap,
          lastDeviceInfo: lastDeviceInfoDisplay,
          activityIndicator: healthIndicators[0].value,
          status4g: { statut: stat4g, isWarning: this.warning4g },
        });

        //--SIMs infos--

        let simReference = device.state.simReference;
        if (!simReference) {
          let responseSimNumber = await DevicesListService.getSimNumberFromDeviceReference(
            device.state.deviceReference
          );
          if (responseSimNumber) {
            simReference = responseSimNumber;
          } else {
            simReference = '';
          }
        }
        if (simReference) {
          this.simList.push({
            poi: device.poi,
            simReference: simReference,
            externalSimReference: simReference.slice(0, -1),
            status: '',
          });
        }
      }
    },
  },
  watch: {
    '$route.params.type': {
      handler: function (newType) {
        this.type = newType;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.info {
  margin-top: 1em;
}
</style>
