<template>
  <div>
    <b-container class="bv-example-row bv-example-row-flex-cols">
      <b-row align-v="center">
        <b-col>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <b-form-group id="input-group-matricule" label="Matricule :" label-for="input-matricule">
              <b-form-input
                id="input-matricule"
                v-model="form.matricule"
                placeholder="Matricule"
                required
              ></b-form-input>
            </b-form-group>

            <b-button class="mr-1" type="submit" variant="primary">Reset</b-button>
            <b-button type="reset" variant="danger">Effacer</b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ControllerService from '@/services/ControllerService';
export default {
  data() {
    return {
      form: {
        matricule: '',
      },
      show: true,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      ControllerService.resetPin(this.form.matricule)
        .then(() => {
          this.$bvToast.toast('Le reset du PIN a été effectué avec succès', {
            title: 'Opération réussie',
            toaster: 'b-toaster-bottom-right',
            variant: 'success',
            solid: true,
          });
        })
        .catch(() => {
          this.$bvToast.toast('Une erreur est survenue lors du reset du PIN', {
            title: 'Erreur',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            solid: true,
          });
        });
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.matricule = '';
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>

<style>
form {
  text-align: left;
}
</style>
