<template>
  <div class="card">
    <div class="card-header">Cartes SIMs</div>
    <div class="overflow-auto">
      <div class="text-right">
        <b-button-group size="sm" class="mt-2 mr-2">
          <b-button variant="outline-disable" disabled>Action groupée</b-button>
          <b-button variant="outline-primary" v-b-modal.activate-modal>Activer</b-button>
          <b-modal id="activate-modal" title="Activation groupée" @ok="activateAll"
            >Voulez-vous activer toutes les SIMs de ce terminal ?
            <ul>
              <li v-for="sim in internalSimList" v-bind:key="sim.externalSimReference">
                {{ sim.externalSimReference }}
              </li>
            </ul>
          </b-modal>
          <b-button variant="outline-primary" v-b-modal.suspend-modal>Suspendre</b-button>
          <b-modal id="suspend-modal" title="Désactivation groupée" @ok="suspendAll"
            >Voulez-vous désactiver toutes les SIMs de ce terminal ?
            <ul>
              <li v-for="sim in internalSimList" v-bind:key="sim.externalSimReference">
                {{ sim.externalSimReference }}
              </li>
            </ul>
          </b-modal>
          <b-button variant="outline-primary" @click="refreshAllStatus()"
            ><b-icon icon="arrow-repeat"></b-icon> Statut</b-button
          >
        </b-button-group>
      </div>
      <b-table
        id="devices-table"
        :items="internalSimList"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :outlined="true"
        :hover="true"
        :small="true"
        responsive
      >
        <template #cell(poi)="data">
          <span v-if="data.item.poi">
            <span>{{ data.item.poi }}</span>
            <a class="ml-1" target="_blank" :href="urlRedirectPOI(data.item.poi)">
              <b-icon icon="box-arrow-up-right"></b-icon
            ></a>
          </span>
        </template>

        <template #cell(externalSimReference)="data">
          <span v-if="data.item.externalSimReference">
            <span>{{ data.item.externalSimReference }}</span>
            <a class="ml-1" target="_blank" :href="urlRedirectSIM(data.item.externalSimReference)">
              <b-icon icon="box-arrow-up-right"></b-icon
            ></a>
          </span>
        </template>

        <template #cell(status)="data">
          <b-badge v-if="data.item.status" :variant="getSimStatusVariant(data.item.status)">{{
            data.item.status
          }}</b-badge>
          <span v-else>
            <b-icon icon="circle-fill" animation="throb"></b-icon>
            <em>Indéfini</em>
          </span>
        </template>

        <template #cell(actions)="data">
          <b-button class="mr-1 mb-1 btn-circle" @click="activateSim(data.item)" variant="info"
            ><b-icon icon="wifi"></b-icon
          ></b-button>
          <b-button class="mr-1 mb-1 btn-circle" @click="suspendSim(data.item)" variant="danger"
            ><b-icon icon="wifi-off"></b-icon
          ></b-button>
          <b-button class="mr-1 mb-1 btn-circle" @click="refreshSimStatus(data.item)"
            ><b-icon icon="arrow-repeat"></b-icon
          ></b-button>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="devices-table"
        align="right"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import SimService from '@/services/SimService';
import { configStore } from '@/store';

export default {
  props: {
    simList: Array,
  },
  data() {
    return {
      internalSimList: [],
      perPage: 4,
      currentPage: 1,
      fields: [
        { key: 'externalSimReference', label: 'SIM' },
        { key: 'poi', label: 'POI' },
        { key: 'status', label: 'Dernier statut' },
        { key: 'actions' },
      ],
    };
  },
  methods: {
    activateSim(sim) {
      SimService.simActivation(sim.externalSimReference)
        .then(() => {
          sim.status = '';
          this.successToast();
        })
        .catch(() => {
          this.errorToast();
        });
    },
    activateAll() {
      let promises = [];
      for (let sim of this.internalSimList) {
        let p = SimService.simActivation(sim.externalSimReference).then(() => {
          sim.status = '';
        });
        promises.push(p);
      }
      Promise.all(promises)
        .then(() => {
          this.successToast();
        })
        .catch(() => {
          this.errorToast();
        });
    },
    suspendSim(sim) {
      SimService.simSuspension(sim.externalSimReference)
        .then(() => {
          sim.status = '';
          this.successToast();
        })
        .catch(() => {
          this.errorToast();
        });
    },
    suspendAll() {
      let promises = [];
      for (let sim of this.internalSimList) {
        let p = SimService.simSuspension(sim.externalSimReference).then(() => {
          sim.status = '';
        });
        promises.push(p);
      }
      Promise.all(promises)
        .then(() => {
          this.successToast();
        })
        .catch(() => {
          this.errorToast();
        });
    },
    refreshSimStatus(sim) {
      SimService.simStatus(sim.externalSimReference)
        .then((response) => {
          sim.status = response.data.status;
          this.successToast();
        })
        .catch(() => {
          sim.status = 'NA';
          this.errorToast();
        });
    },
    refreshAllStatus() {
      let promises = [];
      for (let sim of this.internalSimList) {
        let p = SimService.simStatus(sim.externalSimReference).then((response) => {
          sim.status = response.data.status;
        });
        promises.push(p);
      }
      Promise.all(promises)
        .then(() => {
          this.successToast();
        })
        .catch(() => {
          this.errorToast();
        });
    },
    urlRedirectPOI(poi) {
      return configStore.config.adminPortalUrl + 'devices/' + poi + '/informations';
    },
    urlRedirectSIM(sim) {
      return configStore.config.transatelPortalUrl + 'park/search?search=' + sim;
    },
    getSimStatusVariant(status) {
      switch (status) {
        case 'Available':
          return 'secondary';
        case 'Suspended':
          return 'danger';
        case 'Active':
          return 'success';
        case 'Terminated':
          return 'info';
        default:
          return 'dark';
      }
    },
    errorToast(message = "Une erreur est survenue lors de la prise en compte de l'action.") {
      this.$bvToast.toast(message, {
        title: 'Erreur',
        toaster: 'b-toaster-bottom-right',
        variant: 'danger',
        solid: true,
      });
    },
    successToast(message = 'Action prise en compte avec succès.') {
      this.$bvToast.toast(message, {
        title: 'Enregistrée',
        toaster: 'b-toaster-bottom-right',
        variant: 'success',
        solid: true,
      });
    },
  },
  computed: {
    rows() {
      return this.internalSimList.length;
    },
  },
  watch: {
    simList: {
      handler(newVal) {
        // Duplicate sim list property to avoid mutation
        this.internalSimList = newVal.map((e) => ({ ...e }));
        // Resolve sim status asynchonously
        for (let sim of this.internalSimList) {
          if (!sim.status) {
            SimService.simStatus(sim.externalSimReference)
              .then((response) => {
                sim.status = response.data.status;
              })
              .catch(() => {
                sim.status = 'NA';
              });
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-group {
  margin-bottom: 1em;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}
</style>
