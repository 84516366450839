<template>
  <div>
    <b-navbar toggleable="lg" type="dark">
      <router-link to="/">
        <b-navbar-brand>
          <img src="./../assets/logo-admin.png" alt="admin portal icon" />
          Portail d'Exploitation
        </b-navbar-brand>
      </router-link>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse v-if="user" id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown>
            <template #button-content> <b-icon icon="phone"></b-icon> Terminaux </template>
            <b-dropdown-item :to="'/research/vehicule'">Véhicules</b-dropdown-item>
            <b-dropdown-item :to="'/research/portillon'">Fixes</b-dropdown-item>
            <b-dropdown-item :to="'/research/patrimoine'">Patrimoines</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="hasRole('ROOT_ADMIN')">
            <template #button-content> <b-icon icon="people-fill"></b-icon> Contrôleurs </template>
            <b-dropdown-item :to="'/controller/add'">Ajouter un contrôleur</b-dropdown-item>
            <b-dropdown-item :to="'/controller/resetpin'">Reset un code PIN</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item v-if="hasRole('ROOT_ADMIN')" :to="'/TransportOffer'"
            ><b-icon icon="credit-card2-back-fill"></b-icon> Règlements</b-nav-item
          >
          <b-nav-item v-if="hasRole('ROOT_ADMIN')" :to="'/statistics'"
            ><b-icon icon="graph-up"></b-icon> Stats</b-nav-item
          >
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav v-if="user" class="ml-auto">
          <b-nav-item :to="'/'" right>{{ user['given_name'] }}</b-nav-item>

          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <b-avatar :src="require('@/assets/img.jpg')"></b-avatar>
            </template>
            <b-dropdown-item href="#">Profile</b-dropdown-item>
            <b-dropdown-item v-on:click="logout">Déconnexion</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<style>
nav {
  background-color: #2c3e50;
  margin-bottom: 2%;
}
.navbar-brand > img {
  height: 40px;
}
</style>

<script>
import AuthenticationService from '../services/AuthenticationService';
import Authenticated from '../mixins/Authenticated';

export default {
  mixins: [Authenticated],
  data() {
    return {
      user: null,
    };
  },
  mounted() {
    AuthenticationService.getInstance()
      .isSignedIn()
      .then((isSignedIn) => {
        if (isSignedIn) {
          AuthenticationService.getInstance()
            .getProfile()
            .then((profile) => {
              this.user = profile;
            });
        }
      });
  },
  methods: {
    logout: function () {
      AuthenticationService.getInstance().signOut();
    },
  },
  watch: {
    '$route.meta.authRequired': {
      handler: function (authRequired) {
        if (authRequired) {
          AuthenticationService.getInstance()
            .getProfile()
            .then((profile) => {
              this.user = profile;
            });
        } else {
          this.user = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
