<template>
  <b-container>
    <div>
      <div class="mb-5">
        <BarTransportOffer></BarTransportOffer>
      </div>
      <div v-if="transportOffer">
        <b-row>
          <b-col>
            <div>
              <span class="h3">
                <b-icon icon="credit-card"></b-icon>
                Transport Offer {{ transportOffer.reference }}
              </span>
              <b-button variant="primary" class="float-right" v-b-modal.modal-transport-offer-details>
                <b-icon icon="code-slash"></b-icon> View details
              </b-button>
              <b-modal id="modal-transport-offer-details" title="TransportOffer details">
                <pre>{{ JSON.stringify(transportOffer, null, 2) }}</pre>
              </b-modal>
            </div>
            <hr />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mb-3">
            <Information :generalInformation="generalInformation"> </Information>
          </b-col>
          <b-col>
            <b-row class="mb-3">
              <b-col>
                <b-card-group deck>
                  <b-card
                    bg-variant="default"
                    border-variant="info"
                    header-border-variant="info"
                    header="Cleared Amount"
                    class="text-center"
                  >
                    <h2>{{ transportOffer.clearedAmount ? transportOffer.clearedAmount : 0 }}€</h2>
                  </b-card>
                  <b-card
                    bg-variant="default"
                    border-variant="danger"
                    header-border-variant="danger"
                    header="Debt Amount"
                    class="text-center"
                  >
                    <h2>{{ transportOffer.debtAmount ? transportOffer.debtAmount : 0 }}€</h2>
                  </b-card>
                  <b-card bg-variant="default" header="Cumul. Amount" class="text-center">
                    <h2>{{ transportOffer.cumulativeAmount ? transportOffer.cumulativeAmount : 0 }}€</h2>
                  </b-card>
                </b-card-group>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col>
                <TransportEvents :events="transportEvents"></TransportEvents>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <History :history="riskManagementHistory"></History>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-container>
</template>

<script>
import History from './History.vue';
import Information from './Information.vue';
import TransportEvents from './TransportEvents.vue';
import BarTransportOffer from './BarTransportOffer.vue';
import TransportOfferService from '@/services/TransportOfferService';

export default {
  name: 'TransportOffer',
  components: {
    History,
    Information,
    TransportEvents,
    BarTransportOffer,
  },
  data() {
    return {
      transportOffer: null,
      riskManagementHistory: [],
      generalInformation: [],
      transportEvents: [],
      transportOfferService: new TransportOfferService(),
    };
  },
  methods: {
    updateTransportOffers: function () {
      this.transportOffer = null;
      this.riskManagementHistory = [];
      this.transportEvents = [];
      this.generalInformation = [];
      let resultat;
      switch (this.$route.params.id[0]) {
        case 'M':
          resultat = this.transportOfferService.offers(this.$route.params.id, 'mastercard');
          break;
        case 'V':
          resultat = this.transportOfferService.offers(this.$route.params.id, 'visa');
          break;
        case 'C':
          alert('Les CB ne sont pas encore pris en compte par ce site.');
          break;
        default:
          alert("Vous devez entrer un numéro d'exploitation.");
          break;
      }
      if (resultat) {
        // send the right data into the right components
        resultat
          .then((value) => {
            this.transportOffer = value.data['content']['0'];
            // We asume actions array is by default ordered in reverse-chronological order
            this.riskManagementHistory = [].concat(value.data['content']['0']['actions']).reverse();
            this.transportEvents = value.data['content']['0']['transportEvents'];
            this.generalInformation = value.data['content']['0'];
          })
          .catch(function (err) {
            console.log(err);
          });
      }
    },
  },
  watch: {
    $route() {
      this.updateTransportOffers();
    },
  },
  beforeMount() {
    this.updateTransportOffers();
  },
};
</script>

<style>
div.bouton-aligne {
  display: inline-block;
  width: 50%;
}

div.bouton-aligne-end {
  display: inline-block;
  width: 50%;
  text-align: end;
}
</style>
