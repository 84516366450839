<template>
  <div>
    <b-container class="bv-example-row bv-example-row-flex-cols">
      <b-row align-v="center">
        <b-col>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <b-form-group id="input-group-lastname" label="Nom :" label-for="input-lastname">
              <b-form-input id="input-lastname" v-model="lastname" placeholder="Nom" required></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-firstname" label="Prénom :" label-for="input-firstname">
              <b-form-input id="input-firstname" v-model="firstname" placeholder="Prénom" required></b-form-input>
            </b-form-group>

            <b-form-group id="select-group-company" label="Exploitant :" label-for="select-company" description="">
              <b-form-select id="select-company" v-model="selected" :options="options" required></b-form-select>
            </b-form-group>

            <b-form-group id="input-group-matricule" label="Matricule :" label-for="input-matricule">
              <b-form-input id="input-matricule" v-model="matricule" placeholder="Matricule" required></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-email" label="Email :" label-for="input-email">
              <b-form-input readonly id="input-email" v-model="email" placeholder="Email" required></b-form-input>
            </b-form-group>

            <b-button class="mr-1" type="submit" variant="primary">Créer</b-button>
            <b-button type="reset" variant="danger">Reset</b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ControllerService from '@/services/ControllerService';
export default {
  data() {
    return {
      lastname: '',
      firstname: '',
      selected: null,
      options: [
        { value: null, text: 'Sélectionnez une option' },
        { value: 1, text: 'Kéolis' },
        { value: 2, text: 'Berthelet' },
      ],
      matricule: '',
      email: '',
      show: true,
    };
  },
  watch: {
    matricule() {
      this.email = ControllerService.matriculeToUsername(this.matricule);
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      ControllerService.createController(this.lastname, this.firstname, this.selected, this.matricule)
        .then(() => {
          this.$bvToast.toast('Le contrôleur a été créé avec succès', {
            title: 'Opération réussie',
            toaster: 'b-toaster-bottom-right',
            variant: 'success',
            solid: true,
          });
        })
        .catch(() => {
          this.$bvToast.toast('Une erreur est survenue lors de la création du contrôleur', {
            title: 'Erreur',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger',
            solid: true,
          });
        });
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.lastname = '';
      this.form.firstname = '';
      this.form.company = '';
      this.form.matricule = '';
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>

<style>
form {
  text-align: left;
}
</style>
