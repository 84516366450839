import Router from 'vue-router';

import Home from '../components/Home';
import AuthSigninCallback from '../components/auth/AuthSigninCallback';
import AuthRenewCallback from '../components/auth/AuthRenewCallback';
import AddControllerForm from '../components/AddControllerForm';
import AuthenticationService from '../services/AuthenticationService';
import ResetPinForm from '../components/ResetPinForm';
import TransportOffer from '../components/TransportOffer/TransportOffer';
import TerminalResearch from '../components/terminalResearch/TerminalResearch';
import Statistics from '../components/Statistics';

let router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/controller/add',
      component: AddControllerForm,
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/controller/resetpin',
      component: ResetPinForm,
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/research/:type',
      component: TerminalResearch,
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/callback*',
      name: 'callback',
      component: AuthSigninCallback,
    },
    {
      path: '/renew*',
      name: 'renew',
      component: AuthRenewCallback,
    },
    {
      path: '/TransportOffer/:id?',
      name: 'TransportOffer',
      component: TransportOffer,
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/statistics',
      component: Statistics,
      meta: {
        authRequired: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  let authRequired = to.matched.some((record) => record.meta.authRequired);
  if (authRequired) {
    AuthenticationService.getInstance()
      .getUser()
      .then(
        (user) => {
          if (user) {
            next();
          } else {
            next('/accessdenied');
          }
        },
        (err) => {
          console.log(err);
        }
      );
  } else {
    next();
  }
});

export default router;
