<template>
  <div>
    <b-card header="Informations génériques" no-body>
      <b-card-body>
        <div v-for="(info, key) in GenericInfo" v-bind:key="key">
          <b-card-sub-title class="mb-2"> {{ info.field }}</b-card-sub-title>
          <b-card-text>
            {{ info.value }}
          </b-card-text>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    GenericInfo: Array,
  },
};
</script>
