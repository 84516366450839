<template>
  <div>Authenticating...</div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService';

/**
 * Used to handle silent token renew.
 * This is not the perfect solution for performance because the full vue app
 * will be mounted in the hidden iframe. The best solution would be to load a
 * static html file referencing the current oidc library version exported by
 * webpack on the public folder during the app build.
 */
export default {
  name: 'AuthRenewCallback',
  data() {
    return {};
  },
  mounted() {
    AuthenticationService.getInstance()
      .signinSilentCallback()
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>
