import DevicesListService from '@/services/DevicesListService.js';
import { configStore } from '../store';

export default {
  companyNameFromCompanyReference(companyReference) {
    let exploitant = 'Inconnu';

    if (configStore.config.compagnyReference) {
      for (const [key, value] of Object.entries(configStore.config.compagnyReference)) {
        if (companyReference == key) {
          exploitant = value;
        }
      }
    }
    return exploitant;
  },

  getTransportModeFullName(transportMode) {
    switch (transportMode) {
      case 'TRAM':
        return 'Tramway';
      case 'SUBWAY':
        return 'Métro';
      case 'BUS':
        return 'Bus';
      case 'PARKING':
        return 'P+R';
      default:
        return 'Inconnu';
    }
  },

  getOnBoardTransportUnit(genericParams) {
    if (genericParams) {
      for (let param of genericParams) {
        if (param.name == 'paramG1') {
          return param.value;
        }
      }
    } else {
      return '';
    }
  },

  getTerminalLocation(genericParams) {
    if (genericParams) {
      for (let i = 0; i < genericParams.length; i++) {
        if (genericParams[i].name == 'CUSTOM_PLACE') {
          return genericParams[i].value;
        }
      }
    } else {
      return '';
    }
  },

  FourGStatusFromReference(statut4g) {
    switch (statut4g) {
      case '1':
        return 'OK';
      case '0':
        return 'KO';
      default:
        return 'Inconnu';
    }
  },

  async stationNameFromStationId(stationId) {
    let stationInfo = await DevicesListService.getStationNameFromId(stationId);
    return stationInfo.data[0].name;
  },

  getInstallationType(installationType) {
    switch (installationType) {
      case 'onboard':
        return 'Embarqué';
      case 'gate':
        return 'Portillon';
      case 'elevator':
        return 'Ascenseur';
      case 'parking':
        return 'P+R';
      default:
        return 'Inconnu';
    }
  },
};
